import PropTypes from "prop-types";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import IdleTimer from 'react-idle-timer';
import Header from "./pages/coachusaOrbit/components/Header/Header";
// import Footer from "./pages/coachusaOrbit/components/Footer/Footer";
import Routes from "./Routes/Routes";

class App extends React.Component {
  static propTypes = {
    children: PropTypes.any
  };

  constructor(props) {
    super(props);
    this.idleTimer = null;
    this.onIdle = this._onIdle.bind(this);
    this.inActiveTime = 900000;
  }
  

  _onIdle = (e) => {
    // this.props.logOutCognito();
    // this.props.switchLoginModalOnOff(false);
    // //due to widget reload with this.
    // document.location = process.env.REACT_APP_FARES_SCHEDULES_LINK;
  };

  render() {

    return (
      <div className="App">
        {this.props.isAuthenticated && <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onIdle={this.onIdle}
          debounce={250}
          timeout={this.inActiveTime} />
          }
        <Header/>
        <Router>
            <Routes />
        </Router>
      </div>
    );
  }
}



export default App;
