import React , { Component } from "react";
//import _ from "lodash";
import { connect } from "react-redux";
import { compose } from "redux";
//import { withRouter } from "react-router-dom";
//import Loader from "../Loader/Loader";
import OrderResultsView from "./OrderResultsView"
import dashboardActions from "../../actions/dashboard/dashboard";
import { Row, Col } from "react-bootstrap";
import ModalDialog from "../ModalDialog/ModalDialog";
import Order from "../ViewOrder/Order";

class OrderResults extends Component {
    constructor(props) {
      super(props);
      this.state={
        open: false
      }
    }


    orderOnClick= e =>{
        e.preventDefault();
        this.props.viewOrderList();
        this.setState(state => ({ open: !state.open }));
    }

    toggleModal = () => this.setState(state => ({ open: !state.open }));


    render() {
        const searchResults  = this.props.oderResults;
        const isLoading= this.props.isAppLoading;

        const modalData= (
            <div>
              <Row>
                <Col xs={12}>
                <Order />
                </Col >
              </Row>
            </div>
              );
        if(searchResults)
        return (
            <div className="search">
                <div className="row">
                    <div className="col s12 m6">
                        <OrderResultsView 
                            searchResults={searchResults}
                            orderOnClick={this.orderOnClick} 
                        />
                         <ModalDialog
                            show={this.state.open}
                            name="Order"
                            body={modalData}
                            isLoading={isLoading}
                            toggleModal={()=> this.toggleModal()}
                      />
                    </div>
                </div>
            </div>
        )
        return null;
    }
}


const mapStateToProps = state => ({
    isAppLoading: state.dashboardReducer.isAppLoading
});

const mapDispatchToProps = dispatch => {
    return {
        viewOrderList: () => dispatch(dashboardActions.viewOrderList()),
    };
};

export default compose(
connect(mapStateToProps, mapDispatchToProps)
)(OrderResults);
