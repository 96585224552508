import axios from "axios";

axios.defaults.headers.common['x-api-key'] = process.env.REACT_APP_ORBIT_API_KEY;

const Api = {

  fetch: path => {
    // if(_.includes(path,"getCyberSourecJWK")){
    // axios.interceptors.request.use(
    // //   function (config) {
    // //   // Do something before request is sent
    // //   console.log("path api", path)
    // //     return error;
    // // },
    // function (error) {
    //   error={
    //     data:{
    //       translationKey:"jwk_error_400"
    //     }
    //   }
    //   console.log("jwk key error in AXIOs",error)
    //   return Promise.reject(error);
    // });
    // }

    return axios.get(path, {
    });
  },
  post: (path, data) => {
    return axios.post(path, data, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
  },
  put: (path, data) => {
    return axios.put(path, data, {
      headers: {

        'Content-Type': 'application/json'
      }
    });
  }
};

export default Api;
