import React from "react";
//import { withRouter } from "react-router-dom";
//import { connect } from "react-redux";
//import { compose } from "redux";
//import _ from "lodash";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.userdata = "";
    this.state = {
      isMobile: false
    };
  }

 
  componentDidMount() {
    if (window.innerWidth < 922) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  }

  componentDidUpdate(prevProps){
    
  }
  

  render() {

    return (
      <div>
        <header className="main-header">
          <div className="container coach-header">
            <div className="navbar-brand main-logo">
              <img
                src={"/assets/images/COACHUSA_logo.svg"}
                alt="logo"
                style={{cursor: "pointer"}}
                //onClick={this.gotoHomePage}
              />
            </div>
         </div>
        
        </header>
      </div>
    );
  }
}



export default Header;
