import api from "../../utils/api/api";
import appConstants from "../../constants/appConstants";

const {
  GET_SEARCH_ITEMS,
  GET_SEARCH_ITEMS_FAILED,
  VIEW_ORDER_ITEMS,
  VIEW_ORDER_ITEMS_FAILED,
  SEND_RECEIPT_EMAIL,
  SEND_RECEIPT_EMAIL_FAILED
} = appConstants;



let orderResponse={};
 

const dashboardActions = {
  //create response object for testing purpose
  
  getSearchItems: searchResponse => dispatch => {
    try {
    api.post(
          `https://cj5qh0g1qb.execute-api.us-east-1.amazonaws.com/dev/order/search`,searchResponse
        )
        .then(
          response => {
            if (typeof response !== "undefined") {
              if (typeof response.data !== "undefined") {
                    return dispatch({
                      type: GET_SEARCH_ITEMS,
                      payload: response.data
                    });
                  
                }
              }
          },
          error => {
            return dispatch({
              type: GET_SEARCH_ITEMS_FAILED,
              payload: error
            });
          }
        );
    } catch (error) {
      return dispatch({
        type: GET_SEARCH_ITEMS_FAILED,
        payload: error
      });
    }
  },

  viewOrderList: () => dispatch => {
    try {
      orderResponse={
        order:{
          orderNumber: "a6432c6b-0cd2-4f2d-a8cf-b49eab7504d4",
          numberOfTickets: "2",
          origin: "Middletown, NY",
          destination: "New York, NY",
          transactionDate: "Jan 15, 2020",
          transactionTime: "13:01 EST",
          orderStatus: "Paid",
          orderTotal: "$8.50"
        },
        customer:{
          firstName: "John",
          middleInitial: "J",
          lastName: "Smith",
          destination: "38-8569-111019",
          email: "coach@coachusa.com",
          streetAddress: "80 W Century road",
          address2: " ",
          city: "Paramus",
          state:"New Jersey",
          zipCode:"07040",
          phoneNumber:"12014563945"
        },
        tickets:[
          {
          ticketId:"38-8569-111019",
          ticketType:"One-way",
          passengerType:"Adult",
          expirationDate: "Apr 15, 2020",
          ridesRemaining: "1",
          travelDate :"",
          price: "$8.00"
        },
        {
          ticketId:"38-8569-111019",
          ticketType:"10 Trip",
          passengerType:"",
          expirationDate: "Apr 15, 2020",
          ridesRemaining: "10",
          travelDate :"",
          price: "$49.00"
        }
      ]
      };
      
      dispatch({
        type: "REQUEST_START"
      });
            //if (typeof orderResponse !== "undefined") {
              return dispatch({
                      type: VIEW_ORDER_ITEMS,
                      payload: orderResponse
                    });
                  
                //}
          
      // api
      //   .fetch(
      //     //`${process.env.REACT_APP_TRANSLATION_ENDPOINT}order/translation/en-us`
      //   )
      //   .then(
      //     response => {
      //       if (typeof response !== "undefined") {
      //         if (typeof response.data !== "undefined") {
      //               return dispatch({
      //                 type: GET_SEARCH_ITEMS,
      //                 payload: response.data
      //               });
                  
      //           }
      //         }
      //     },
      //     error => {
      //       return dispatch({
      //         type: GET_SEARCH_ITEMS_FAILED,
      //         payload: error
      //       });
      //     }
      //   );
    } catch (error) {
      return dispatch({
        type: VIEW_ORDER_ITEMS_FAILED,
        payload: error
      });
    }
  },

  sendEmailReceipt: receiptRequest => dispatch => {
      try {
        dispatch({
          type: "REQUEST_START"
        });
        api
          .post(
            `${process.env.REACT_APP_GET_RECEIPT}/order/receipt/send`,receiptRequest
          )
          .then(
            response => {
              if (typeof response !== "undefined") {
                if (typeof response.data !== "undefined") {
                      return dispatch({
                        type: SEND_RECEIPT_EMAIL,
                        payload: response.data
                      });
                    
                  }
                }
            },
            error => {
              return dispatch({
                type: SEND_RECEIPT_EMAIL_FAILED,
                payload: error
              });
            }
          );
      } catch (error) {
        return dispatch({
          type: SEND_RECEIPT_EMAIL_FAILED,
          payload: error
        });
      }
    },

};

export default dashboardActions;
