import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { Auth } from "aws-amplify";
import {  Link, withRouter, Redirect} from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { compose } from "redux";
import Loader from "../Loader/Loader";
import authActions from "../../actions/auth/auth";
import headerActions from "../../actions/header/header";
import LoginView from "../Login/LoginView";
import translationUtils from "../../utils/translationUtils/translationUtils";

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
            email: "",
            password: "",
      error: "",
      hidden: true,
      loggedInUser:{},
      isLoading:false,
      authState:null
    };
  }

  handleChange = e => {
    let value = e.target.value;
    if (e.target.name === "email") value = value.toLowerCase();
    this.setState({
        [e.target.name]: value
      });
    //this.setState({ [e.target.name]: value, passError: "", error: "", emailError: "" });
    
  };

  handleSubmit = event => {
    event.preventDefault();
    window.location.href='./searchorder'
    //this.setState({ passError: "", error: "", emailError: "" });
    const { email, password } = this.state;

    if (_.isEmpty(email)) {
      this.setState({ emailError: translationUtils.getTranslation(this.props.translationItems.orbitResponse, "email_id_required_login", "Email Id is required")
      });
      return;
    } else {
      let emailpattern = new RegExp(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
      if (!emailpattern.test(email)) {
        this.setState({ emailError: translationUtils.getTranslation(this.props.translationItems.orbitResponse, "email_Id_not_valid_login", "Please enter valid email Id")
        });
        return;
      }
    }
    if (_.isEmpty(password)) {
      this.setState({ passError: translationUtils.getTranslation(this.props.translationItems.orbitResponse, "password_required_login", "Password is required")
      });
      return;
    } else {
      if (password.length < 8) {
        this.setState({ passError: translationUtils.getTranslation(this.props.translationItems.orbitResponse, "password_invalid_login", "Please enter valid password")
        });
        return;
      }
    }

    let loginResponse = {};
    let userData = null;
    this.setState({ isLoading: true });
    Auth.signIn(email.toLowerCase(), password)
      // If we are successful, navigate to Home screen
      .then(user => {
        Auth.currentAuthenticatedUser();
        //this.props.currentAuthenticatedUser();
        this.setState({ isLoading: false });
        //this.props.switchLoginModalOnOff(false);
        
        this.props.history.push("/")
        //document.location = document.location; // Reload page to refresh values (e.g. credit card)
      })
      // On failure, display error in console
      .catch(err => {

        this.setState({ isLoading: false });
        if (err !== null && err === "UserNotConfirmedException") {
          this.setState({
            error:
              "Account verification required: please check your email to confirm account."
          });
        } else if (err !== null && err === "NotAuthorizedException") {
          this.setState({
            error: "The email is not authenticated."
          });
        } else {
          this.setState({
            error:
              "Sorry, we didn't recognize that email and password combination. Please try again."
          });
        }
      });
    // }
  };

  toggleShow = () => {
    this.state.password !== ""
      ? this.setState({ hidden: !this.state.hidden })
      : this.setState({ hidden: true });
  };

  render() {
    const { error } = this.props;

    return (
      <div>
        {this.state.isLoading && (
          <div className="spinner-bg">
            <Spinner animation="border" variant="primary" />
          </div>
        )}
        <div className="container">
          <div className="row">
            <div className="signup-section">
            <div className={_.isEmpty(error) ? "no-error" : "error-msg"}>
                {error}
            </div>
             <LoginView email={this.state.email}  password={this.state.password} handleChange={this.handleChange} handleSubmit={this.handleSubmit}/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// const mapStateToProps = state => ({
//   ...state,
//   authUser: state.authReducer.authUser,
//   loggedInUser: state.authReducer.loggedInUser,
//   isLoading: state.authReducer.isLoading,
//   loginError: state.authReducer.loginError,
//   isAuthenticated: state.authReducer.isAuthenticated,
//   userError: state.authReducer.userError,
//   showSignUpToggle: state.headerReducer.showSignUpToggle,
// });

// const mapDispatchToProps = dispatch => ({
//   authLogin: (email, password) =>
//     dispatch(authActions.authLogin(email, password)),
//   loginCognito: (email, password) =>
//     dispatch(authActions.loginCognito(email, password)),
//   currentAuthenticatedUser: () =>
//     dispatch(authActions.currentAuthenticatedUser()),
//   switchLoginModalOnOff: toggle =>
//     dispatch(headerActions.switchLoginModalOnOff(toggle)),
//     switchSignUpModalOnOff: toggle =>
//     dispatch(headerActions.switchSignUpModalOnOff(toggle))
// });

// export default compose(withRouter,
//   connect(mapStateToProps, mapDispatchToProps)
//   )(Login);
export default compose(withRouter)(Login) ;