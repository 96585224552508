import { combineReducers } from "redux";
import authReducer from "./auth/authReducer";
import headerReducer from "./header/headerReducer";
import dashboardReducer from "./dashboard/dashboardReducer";
//import errorMessages from "./errorMessages/errorMessages";

export default combineReducers({
  
  authReducer,
  headerReducer,
  dashboardReducer,
  //errorMessages
});
