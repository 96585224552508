import 'react-app-polyfill/ie11';
import ReactDOM from "react-dom";
import React from "react";
import { Provider } from "react-redux";
import configureStore from "./store/configureStore";
import App from "./App";
import "babel-polyfill";
import Amplify from "aws-amplify";
import "./style/index.scss";

const render = Component => {
  Amplify.configure({
    Auth: {
      mandatorySignIn: true,
      region: process.env.REACT_APP_COGNITO_REGION,
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID
    }
  });

  ReactDOM.render(
    <Provider store={configureStore}>
      <Component />
    </Provider>,
    document.getElementById("root")
  );
};

render(App);

if (module.hot) {
  module.hot.accept("./App", () => {
    const NextApp = require("./App").default;
    render(NextApp);
  });
}


