const initialState = {
  isAppLoading: false,
  error: "",
  searchResults: [],
  orderItems:{},
  sendReceiptData:{},
  sendReceiptError:{},
  viewOrderError:{},
  searchResultsError:{}
};
export default (state = initialState, action) => {
  switch (action.type) {
    case "REQUEST_START":
      return { 
        ...state,
        isAppLoading:true
      };
    case "GET_SEARCH_ITEMS":
      return { 
        ...state,
        searchResults: action.payload,
        isAppLoading:false
      };
    case "GET_SEARCH_ITEMS_FAILED":
      return { 
        searchResultsError: action.payload,
        isAppLoading:false
      };
    case "VIEW_ORDER_ITEMS":
        return { 
          ...state,
          orderItems: action.payload,
          isAppLoading:false
        };
    case "VIEW_ORDER_ITEMS_FAILED":
        return { 
          viewOrderError: action.payload,
          isAppLoading:false
        };
    case "SEND_RECEIPT_EMAIL":
          return { 
            ...state,
            sendReceiptData: action.payload,
            isAppLoading:false
          };
    case "SEND_RECEIPT_EMAIL_FAILED":
          return { 
            sendReceiptError: action.payload,
            isAppLoading:false
          };
    default:
      return state;
  }
};
