import React from "react";
import { Row, Col, FormLabel,Form,Button } from "react-bootstrap";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import NumberFormat from "react-number-format";

const OrderSearchView = (props) => {
  const fromDate = props.fromDate?props.fromDate:new Date().setMonth(new Date().getMonth() - 6);
  const toDate = props.toDate?props.toDate:new Date();
    return (
      <div>
      <div className="project-list section">
        <h2>Order Search</h2>
        <Row className="checkout-page-row">
          <Col md={8} xs={12}>
            <Form>
                  <Row>
                    <Col>
                      <Form.Group className="float-label-control">
                      
                        <Form.Control
                          type="text"
                          name="orderId"
                          placeholder="Order Number"
                          value ={props.orderId}
                          onChange={props.handleChange}
                        />
                       </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="float-label-control">
                      
                        <Form.Control
                          type="text"
                          name="firstName"
                          placeholder="First Name"
                          value ={props.firstName}
                          onChange={props.handleChange}
                        />
                       
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6} xs={12}>
                        
                      <Form.Group className="float-label-control">
                      <Col md={6} xs={12}>
                      <DatePicker
  
                                id={'date'}
                                onChange={props.handleChangeDate}
                                dateFormat="MMMM d, yyyy"
                                timeCaption="time"
                                className="form-control"
                                value={fromDate}
                                selected={fromDate}
                                placeholderText="From"
                            />
                            </Col>
                            <Col md={6} xs={12}>
                             <DatePicker
                                id={'date'}
                               onChange={props.handleChangeTodate}
                                dateFormat="MMMM d, yyyy"
                                timeCaption="time"
                                className="form-control"
                                value={toDate}
                                selected={toDate}
                                placeholderText="To"
                            />
                            </Col>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="float-label-control">
                      
                        <Form.Control
                          type="text"
                          name="lastName"
                          placeholder="Last Name"
                          value ={props.lastName}
                          onChange={props.handleChange}
                        />
                        </Form.Group>
                    </Col>
                    </Row>
                    <Row>
                    <Col md={6} xs={12}>
                      <Form.Group className="float-label-control">

                      <Form.Control
                          type="text"
                          name="ticketId"
                          value={props.ticketId}
                          placeholder="Ticket ID"
                          onChange={props.handleChange}
                         
                        />
                      
                        </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="float-label-control">
                      
                        <Form.Control
                          type="text"
                          name="email"
                          value={props.email}
                          placeholder="Email address"
                          onChange={props.handleChange}
                         
                        />
                       
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} xs={12}>
                      <Form.Group className="float-label-control">

                      {/* <Form.Control
                          type="number"
                          name="lastFourDigit"
                          value={props.lastFourDigit}
                          placeholder="Last 4 digits of credit card number"
                          onChange={props.handleChange}
                          maxLength="4"
                         
                        /> */}

                         <NumberFormat
                            name="lastFourDigit"
                            placeholder="Last 4 digits of credit card number"
                            value={props.phoneNumber}
                            onChange={props.handleChange}
                            className="form-control"
                            maxLength="4"
                            
                          />
                       
                        </Form.Group>
                    </Col>
                    <Col md={6} xs={12}>
                      <Form.Group className="float-label-control">

                         <NumberFormat
                            format="###-###-####"
                            mask="_"
                            name="phoneNumber"
                            placeholder="Phone Number"
                            value={props.phoneNumber}
                            onChange={props.handleChange}
                            className="form-control"
                            
                          />
                        
                        </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} xs={12}>
                      <Form.Group className="float-label-control">
                        <FormLabel className="form-control-placeholder">
                          Service operation
                        </FormLabel>
                        <Select
                          className="card-type"
                          placeholder="Shortline"
                          name="serviceOperation"
                          value="Shortline"
                          options={"Shortline"}
                          onChange={props.handleChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <div>
                <Row>
                  <Col>
                      <Button
                        type="submit"
                        className="btn btn-secondary"
                        onClick={props.resetSearchFields}
                      >
                        Reset
                      </Button>
                  </Col>
                
                  <Col>
                      <Button
                        type="submit"
                        className="btn btn-secondary"
                        onClick={props.onSearchClick}
                      >
                        Search
                      </Button>
                  </Col>
                </Row>
              </div>
             </Form>
             </Col>
             </Row>
             </div>
             </div>
    )
  }
  
export default OrderSearchView;

