import React , { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Row, Col, Form } from "react-bootstrap";
import OrderView from "./OrderView"
import ModalDialog from "../ModalDialog/ModalDialog";
import dashboardActions from "../../actions/dashboard/dashboard";

class Order extends Component {
    popUpName="";
    constructor(props) {
      super(props);
      this.state={
        open: false,
        email:""
      }
    }

    openEmailPopup = (e) =>{
        e.preventDefault();
        this.popUpName="emailSend"
        this.toggleModal();
    }

    goToRefundUrl =(e) =>{
        e.preventDefault();
        const URL = process.env.REACT_APP_CYBERSOURCE_REFUND_URL;
        window.open(URL, '_blank', "location=yes,height=570,width=520,scrollbars=yes,status=yes");
    }

    onSendEmail=()=>
    {
        const receiptRequest={
            orderId:"09e9fe65-f2d9-4aed-a3c9-aa89a7c932bf",
            email: this.state.email
        }
        this.props.sendEmailReceipt(receiptRequest);
    }

    toggleModal = () => this.setState(state => ({ open: !state.open }));

    componentDidUpdate(prevProps){
        if(prevProps.sendReceiptData !== this.props.sendReceiptData){
            this.toggleModal();
            this.popUpName="sendEmailMsg";
            this.toggleModal();
        }

        if(prevProps.sendReceiptError !== this.props.sendReceiptError){
            
        }
    }

    handleChange = event => {
        let value = event.target.value;
        if (event.target.name === "email") value = value.toLowerCase();
        this.setState({
          [event.target.name]: value
        });
        
      };

    render() {
       
        const modalData= (
            <div>
                {this.popUpName==="sendEmailMsg"?
                <Row className="custom-modal-popup-email">
                    <Col md={12} xs={12}>
                        <h3> Email Sent </h3>
                    </Col>
                </Row>
                :
              <Row>
                <Col xs={12}>
                <Form>
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" name="email" placeholder="Enter email" onChange={this.handleChange} value={this.state.email || ''} />
                  
                </Form.Group>
                </Form>
                </Col>
              </Row>
                }
            </div>
            );

        const orderItems = this.props.orderItems;
        return (
            <div className="search">
                <div className="row">
                    <div className="col s12 m6">
                        <OrderView 
                            orderItems={orderItems}
                            openEmailPopup={this.openEmailPopup}
                            goToRefundUrl={this.goToRefundUrl}
                        />
                         <ModalDialog
                            show={this.state.open}
                            name={this.popUpName}
                            body={modalData}
                            isLoading={this.props.isAppLoading}
                            onSendEmail={this.onSendEmail}
                            onCancel={this.toggleModal}
                      />
                    </div>
                </div>
            </div>
        )
    }
}

  
const mapStateToProps = state => ({
    orderItems: state.dashboardReducer.orderItems,
    sendReceiptData: state.dashboardReducer.sendReceiptData,
    sendReceiptError: state.dashboardReducer.sendReceiptError,
    isAppLoading: state.dashboardReducer.isAppLoading
});

const mapDispatchToProps = dispatch => {
    return {
        sendEmailReceipt: (receiptRequest) => dispatch(dashboardActions.sendEmailReceipt(receiptRequest))
    };
};

export default compose(
connect(mapStateToProps, mapDispatchToProps)
)(Order);
