const initialState = {
   showLoginToggle:false,
   showSignUpToggle:false,
   error:{},
   backdrop:"backdrop",
  };
  
export default (state = initialState, action) => {
    switch (action.type) {
        case "SWITCH_LOGIN_MODAL_ON_OFF":
            return {
            ...state,
            showLoginToggle: action.payload,
            //backdrop:action.payload===true? "backdrop backdrop-visible": "backdrop",
            };
        case "SWITCH_LOGIN_MODAL_ON_OFF_ERROR":
            return {
            ...state,
            error: action.payload
            };
        case "SWITCH_SIGNUP_MODAL_ON_OFF":
            return {
              ...state,
              showSignUpToggle: action.payload,
              //backdrop:action.payload===true? "backdrop backdrop-visible": "backdrop",
            };
        case "SWITCH_SIGNUP_MODAL_ON_OFF_ERROR":
            return {
              ...state,
              error: action.payload
            };
      default:
        return state;
    }
  };
  