import React from 'react';
import { Row, Col, Table } from "react-bootstrap";
//import ModalDialog from "../ModalDialog/ModalDialog";
//import Order from "../ViewOrder/Order";

const  OrderResultsView=(props)=> {
  //const onloading=true;
    return (
      <div className="search-result">
        <Row>
            <Col>
            <Table responsive>
                <thead>
                    <tr>
                    <th>Order number</th>
                    <th>Purchase date</th>
                    <th>Ticket number</th>
                    <th>First name</th>
                    <th>Last name</th>
                    <th>Email address</th>
                    <th>Phone number</th>
                    </tr>
                </thead>
                <tbody>
                {props.searchResults.map((data, index) =>
                <tr key={`search`+index}>
                <td><a href="#" onClick={props.orderOnClick}> {data.orderNumber} </a></td>
                <td> {data.purchaseDate}</td>
                <td> {data.ticketNo} </td>
                <td> {data.firstName} </td>
                <td> {data.lastName} </td>
                <td> {data.emailAddress} </td>
                <td> {data.phoneNumber} </td>
                </tr>
                )}
                </tbody>
            </Table>
           
            </Col>
        </Row>
      </div>
    );
  }

  
      
  export default OrderResultsView