import React from "react";

import { FormLabel, FormControl,Button } from "react-bootstrap";

const Login= (props)=>{


    return (
      <div>
      <form>
        <div className="signup-section signup-lightbox">
          <div>
            <FormLabel className="form-control-placeholder text-left">
              Email Address
            </FormLabel>
            <FormControl
                        type="email"
                          name="email"
                          placeholder="Email Address"
                          value={props.email}
                          onChange={props.handleChange}
                        />
          </div>
          
          <div>
            <FormLabel className="form-control-placeholder text-left">
              Password
            </FormLabel>
            <div className="password">
              {/* <EyeIcon
                alt="viewPassword"
                className="password"
                onClick={this.toggleShow}
              /> */}
            </div>
            <FormControl
                        type={props.hidden ? "password" : "text"}
                          name="password"
                          placeholder="Password"
                          value ={props.password}
                          onChange={props.handleChange}
                          //style={{zIndex: "99999990"}}
                        />
          </div>
          <div
            //onClick={this.gotoForgotPassword}
            style={{width: "140px", disabled:true, float: "right", margin: "0 5px 0 0", textAlign: "right", color: "#29A1FE", textDecoration: "none", fontSize: "16px", cursor: "pointer"}}
          >
            Forgot Password?
          </div>
          {/* <div
            className={_.isEmpty(passError) ? "no-error" : "error-msg"}
          >
            {this.state.passError}
          </div> */}
          <div className="text-center">
            <Button
              type="submit"
              onClick={props.handleSubmit}
              //disabled={((props.email.length === 0) || (props.password.length < 8))}
              className="coachwidget-btn btn-login"
            >
              Log In
            </Button>
          </div>
          {/* <div style={{ textAlign: "center", color: "Red" }}>
            {authError ? <p>{authError}</p> : null}
          </div> */}
        </div>
      </form>
      </div>
    );
  
}

export default Login;