import React from "react";
import { Switch, Route } from "react-router-dom";
import Dashboard from "../pages/coachusaOrbit/components/Dashboard/Dashboard";
import Login from "../pages/coachusaOrbit/components/Login/Login";
import OrderSearch from "../pages/coachusaOrbit/components/OrderSearch/OrderSearch";

export default ({ childProps }) => (
  <Switch>
   
    <Route exact path="/" component={Login} />
    <Route path="/home" component={Login} />
    <Route path="/searchorder" component={OrderSearch} />
  </Switch>
);
