import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import OrderSearchView from "./OrderSearchView";
import dashboardActions from "../../actions/dashboard/dashboard";

class OrderSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchFields: {
                fromDate: new Date(new Date().setMonth(new Date().getMonth() - 6)),
                toDate: new Date(),
                orderId: '',
                firstName: '',
                lastName: '',
                ticketId: '',
                email: '',
                lastFourDigit: '',
                phoneNumber: '',
                serviceOperation: ''
            },
            searchErrors: {
                fromDate: new Date(new Date().setMonth(new Date().getMonth() - 6)),
                toDate: new Date(),
                orderId: '',
                firstName: '',
                lastName: '',
                ticketId: '',
                email: '',
                lastFourDigit: '',
                phoneNumber: '',
                serviceOperation: ''
            }

        }

    };

    onSearchClick = event => {
        event.preventDefault();
        const searchFields = this.state.searchFields;
        searchFields.fromDate = this.state.searchFields.fromDate.toISOString();
        searchFields.toDate = this.state.searchFields.toDate.toISOString();
        this.props.getSearchItems(searchFields);
     };

    handleChangeDate = (date) => {
        let searchFields = this.state.searchFields;
        searchFields.fromDate = date;
        this.setState({ searchFields }, () => {
            this.validate_dates();
        });
    };

    handleChangeTodate = (date) => {
        let searchFields = this.state.searchFields;
        searchFields.toDate = date;
        this.setState({ searchFields }, () => {
            this.validate_dates();
        });

    };

    handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        let searchFields = this.state.searchFields;
        searchFields[name] = e.target.value;
        this.setState({ searchFields });
        let searchErrors = this.state.searchErrors;


        switch (name) {
            case "emailAddress":
                const emailValue = value.toLowerCase();
                const emailpattern = new RegExp(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
                if (!emailpattern.test(emailValue)) {
                    searchErrors.email = "Please enter a valid email address"
                } else {
                    searchErrors.email = "";
                }
                break;

            case "phoneNumber":
                const mobilepattern = new RegExp(/^\d{3}-\d{3}-\d{4}$/i);
                if (value && !mobilepattern.test(value)) {
                    searchErrors.phoneNumber = "Please provide a valid phone number"

                } else {
                    searchErrors.phoneNumber = "";
                }
                break;
            default:
                break;
        }

        this.setState({ searchErrors });

    };

    validate_dates = () => {
        if (new Date(this.state.startDate).getTime() > new Date(this.state.endDate).getTime()) {
            alert("End Date should be greater than Start Date");
            this.setState({
                fromDate: '',
                toDate: ''
            })
        }
    }

    resetSearchFields = (e) => {
        e.preventDefault();
        const name = e.target.name;
        let searchFields = this.state.searchFields;
        searchFields[name] = '';
        this.setState({ searchFields });
    }

    render() {

        return (
            <div className="dashboard container">
                <div className="row">
                    <div className="col s12 m6">
                        <OrderSearchView onSearchClick={this.onSearchClick} handleChangeDate={this.handleChangeDate} handleChangeTodate={this.handleChangeTodate} fromDate={this.state.searchFields.fromDate} toDate={this.state.searchFields.toDate} handleChange={this.handleChange} resetSearchFields={this.resetSearchFields} />
                    </div>
                </div>

            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getSearchItems: searchFields => dispatch(dashboardActions.getSearchItems(searchFields))
    };
};

export default compose(
    connect(null, mapDispatchToProps)
)(OrderSearch);
