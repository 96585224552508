import React from 'react';
import { Row, Col, Table, Button } from "react-bootstrap";
//import ModalDialog from "../ModalDialog/ModalDialog";
import _ from "lodash";

const  OrderView=(props)=> {
    const order=_.get(props.orderItems, "order");
    const customer=_.get(props.orderItems, "customer");
    const tickets=_.get(props.orderItems, "tickets");
    const email=customer.email;
    
    return (
      <div className="search-result">
          <h2>View Order- {order.orderNumber} </h2>
          <Row>
              <Col  md={6} xs={6}>
              <div style={{align:"left"}}>
              <h3> Order information </h3>
                <Row>
                    <Col>
                    Order number
                    </Col>
                    <Col>
                        {order.orderNumber}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        Number of Tickets
                    </Col>
                    <Col>
                        {order.ticketCount}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        Origin
                    </Col>
                    <Col>
                        {order.origin}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        Destination
                    </Col>
                    <Col>
                        {order.destination}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        Transaction date
                    </Col>
                    <Col>
                        {order.transactionDt}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        Transaction time
                    </Col>
                    <Col>
                        {order.transactionTime}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        Order status
                    </Col>
                    <Col>
                        {order.orderStatus}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        Order total
                    </Col>
                    <Col>
                        {order.orderTotal}
                    </Col>
                </Row>
                <Row>
                    <Col>
                    <Button
                          type="submit"
                          className="btn"
                          onClick={props.openEmailPopup}
                        >
                            Email tickets and receipt
                    </Button>
                    </Col>
                    <Col>
                    <Button
                          type="submit"
                          className="btn"
                          onClick={props.goToRefundUrl}
                        >
                            Refund
                    </Button>
                    </Col>
                </Row>
                
              </div>
              </Col>
              <Col md={6} xs={6}>
                  <div style={{align:"right"}}>
                  <h3> Customer information </h3>
                  <Row>
                    <Col>
                    First name
                    </Col>
                    <Col>
                        {customer.firstName}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        Middle initial
                    </Col>
                    <Col>
                        {customer.middlIinitial}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        Last Name
                    </Col>
                    <Col>
                        {customer.lastName}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        Email address
                    </Col>
                    <Col>
                        {customer.email}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        Street Address
                    </Col>
                    <Col>
                        {customer.streetAddress}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        Address line 2
                    </Col>
                    <Col>
                        {customer.address2}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        City
                    </Col>
                    <Col>
                        {customer.city}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        State
                    </Col>
                    <Col>
                        {customer.state}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        Zip code
                    </Col>
                    <Col>
                        {customer.zipCode}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        Phone number
                    </Col>
                    <Col>
                        {customer.phoneNumber}
                    </Col>
                </Row>
                  </div>
              </Col>
          </Row>
        <Row>
            <Col md={12} xs={12}>
                Ticket information
            <Table responsive>
                <thead>
                    <tr>
                    {/* <th>
                    <Form.Check
                        custom
                        inline
                        label=""
                        type="checkbox"
                        id={`order-checkbox-all`}
                    />
                    </th> */}
                    <th>Ticket ID</th>
                    <th>Ticket type</th>
                    <th>Passenger type</th>
                    <th>First name</th>
                    <th>Last name</th>
                    <th>Email address</th>
                    <th>Phone number</th>
                    </tr>
                </thead>
                <tbody>
                {tickets.map((data, index) =>
                <tr key={`order`+index}>
                {/* <td className="mb-3"> 
                <Form.Check
                    custom
                    inline
                    label=""
                    type="checkbox"
                    id={`order-checkbox-${index}`}
                />
                </td> */}
                <td> {data.ticketId}</td>
                <td> {data.ticketType} </td>
                <td> {data.passengerType} </td>
                <td> {data.expirationDate} </td>
                <td> {data.ridesRemaining} </td>
                <td> {data.travelDate} </td>
                <td> {data.price} </td>
                </tr>
                )}
                </tbody>
            </Table>
         
            </Col>
        </Row>
        
        
      </div>
    )
  }
  
  export default OrderView