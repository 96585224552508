import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import OrderSearch from "../OrderSearch/OrderSearch";
import OrderResults from "../OrderResults/OrderResults";
import dashboardActions from "../../actions/dashboard/dashboard";


class Dashboard extends React.Component {


  // onSearchClick=()=>{
  //   this.props.getSearchItems();
  // }

  render() {
  
    const oderResults=this.props.searchResults?this.props.searchResults :null;

    return (
    <div className="dashboard container">
     <div className="row">
     <h3> Welcome User</h3>
        <div className="col s12 m6">
          <OrderSearch/>
        </div>
        </div>
        <div className="row"> </div>
        <div className="search">
        <div className="col s12 m6">
          {
            oderResults.length !==0 ?
            <OrderResults oderResults={oderResults}/>
            :
            null
          }
         
        </div>
     </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  searchResults: state.dashboardReducer.searchResults,
});
    
const mapDispatchToProps = dispatch => {
  return {
      getSearchItems: () => dispatch(dashboardActions.getSearchItems())
  };
};

export default compose(
connect(mapStateToProps, mapDispatchToProps)
)(Dashboard);
