import React from "react";
import { Modal, Button, Row, Col, Spinner } from "react-bootstrap";

const ModalDialog = props => {
  return (
    <Modal
      className="custom-modal-popup payment-success"
      show={props.show}
      onHide={props.toggleModal}
    >
      <Modal.Body className="credit-card-poup">
        {props.isLoading && (
            <div className="spinner-bg">
              <Spinner animation="border" variant="primary" />
            </div>
          )}
        {props.body}
      </Modal.Body>
      <Modal.Footer>
        <div>
          {props.name==="Order"?
          <Button type="submit" className="btn" onClick={props.toggleModal}>
            Back to results
          </Button>
          :
          props.name === "sendEmailMsg"?
          
          <Button type="submit" className="primary" onClick={props.onCancel}>
          Close
        </Button>
          
          :
          <Row>
            <Col xs={6}>
            <Button type="submit" className="btn" onClick={props.onSendEmail}>
            Send
          </Button>
            </Col>
            <Col xs={6}>
            <Button type="submit" className="btn" onClick={props.onCancel}>
           Cancel
         </Button>
            </Col>
          </Row>
          
          
        }
          
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalDialog;
