const translationUtils = {
    getTranslation(oTranslationsFromApi,inKey,sDefaultUntranslated){
        let sTranslatedMessage = sDefaultUntranslated // set a default in the unhappy case that the API failed, or if the key is not found
        if(typeof oTranslationsFromApi !== 'undefined'){
            if(typeof oTranslationsFromApi.find(x => x.translation_key === inKey) !== 'undefined'){
                sTranslatedMessage = oTranslationsFromApi.find(x => x.translation_key === inKey).description;
            }
        }
        return sTranslatedMessage;
    }
};

export default translationUtils;